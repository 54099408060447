import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Auth } from '../pages/auth';
import { SignUp } from '../pages/auth/sign-up';
import { LogIn } from '../pages/auth/log-in';
import { ForgotPassword } from '../pages/auth/forgot-password';
import { RegistrationSuccess } from '../pages/auth/registration-success';
import { ChangePasswordSuccess } from '../pages/auth/change-password-success';
import { EditProfile } from '../pages/profile/edit-profile';
import { Profile } from '../pages/profile';
import { CheckYourEmail } from '../pages/auth/check-your-email';
import { ActivateEmail } from './../pages/auth/activate-email';
import { Password } from '../pages/profile/password';
import { ResetPasswordConfirm } from '../pages/auth/reset-password-confirm';
import { Analytics } from '../pages/analytics';
import { AssistantMenu } from '../pages/assistant';
import { Assistant } from '../pages/assistant/Assistant';
import { AssistantTopic } from '../pages/assistant/AssistantTopic';
import { AssistantSuccess } from '../pages/assistant/AssistantSuccess';
import { AssistantChat } from '../pages/assistant/AssistantChat';
import { CardViewLayout } from '../layouts/CardViewLayout/CardViewLayout';
import { SideBarLayout } from '../layouts/SideBarLayout/SideBarLayout';
import { Loading } from '../components/Loading/Loading';
import { PrivateRoute } from '../components/PrivateRoute/PrivateRoute';
import { Paths } from '../shared/const/route-paths';
import { userSelectors } from '../stores';
import {
  useAppSelector,
  useAuthenticateUser,
  useLogout,
  useUserData,
} from '../hooks/hooks';
import './App.css';

export const App = () => {
  const isAuthenticated = useAppSelector(userSelectors.getIsLoggedIn);
  const loading = useAppSelector(userSelectors.getLoading);

  useUserData();
  useAuthenticateUser();
  useLogout();

  if (loading) {
    return <Loading size="large" />;
  }

  return (
    <React.Suspense fallback={<Loading />}>
      <Routes>
        <Route path={'/'} element={<CardViewLayout />}>
          <Route path={Paths.AUTH} element={<Auth />} />
          <Route path={Paths.SIGN_UP} element={<SignUp />} />
          <Route path={Paths.LOG_IN} element={<LogIn />} />
          <Route path={Paths.FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route path={Paths.CHECK_YOUR_EMAIL} element={<CheckYourEmail />} />
          <Route path={Paths.ACTIVATE_EMAIL} element={<ActivateEmail />} />
          <Route
            path={Paths.RESET_PASSWORD_CONFIRM}
            element={<ResetPasswordConfirm />}
          />
          <Route
            path={Paths.REGISTRATION_SUCCESS}
            element={<RegistrationSuccess />}
          />
          <Route
            path={Paths.CHANGE_PASSWORD_SUCCESS}
            element={<ChangePasswordSuccess />}
          />

          <Route index element={<Navigate replace to={Paths.AUTH} />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Route>

        <Route
          path={Paths.APP.INDEX}
          element={
            <PrivateRoute isAuthenticated={!isAuthenticated}>
              <SideBarLayout />
            </PrivateRoute>
          }
        >
          <Route path={Paths.APP.ANALYTICS} element={<Analytics />} />
          <Route path={Paths.APP.ASSISTANT.INDEX} element={<AssistantMenu />}>
            <Route index element={<Assistant />} />
            <Route
              path={Paths.APP.ASSISTANT.TOPIC}
              element={<AssistantTopic />}
            />
            <Route
              path={Paths.APP.ASSISTANT.CHAT}
              element={<AssistantChat />}
            />
            <Route
              path={Paths.APP.ASSISTANT.SUCCESSVIEW}
              element={<AssistantSuccess />}
            />
            <Route
              path="*"
              element={<Navigate replace to={Paths.APP.ASSISTANT.INDEX} />}
            />
          </Route>
          <Route path={Paths.APP.PROFILE.INDEX} element={<Profile />}>
            <Route
              path={Paths.APP.PROFILE.EDIT_PROFILE}
              element={<EditProfile />}
            />
            <Route path={Paths.APP.PROFILE.PASSWORD} element={<Password />} />
            <Route
              index
              element={<Navigate replace to={Paths.APP.PROFILE.EDIT_PROFILE} />}
            />
          </Route>
          <Route
            path="*"
            index
            element={<Navigate replace to={Paths.APP.PROFILE.INDEX} />}
          />
        </Route>
      </Routes>
    </React.Suspense>
  );
};
