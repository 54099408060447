export const Q1_INSURANCE_DATA = {
  id: 6,
  question: 'What is my current insurance cost per mile?',
  inputs: {
    'Enter total insurance cost and total miles for last year.': {
      title: 'Enter total insurance cost and total miles for last year.',
      type: 'multiple-inputs',
      validate: 'number',
      inputs: [
        {
          name: 'total insurance cost',
          label: 'Total insurance cost',
          validate: 'number',
        },
        {
          name: 'total miles',
          label: 'Total miles',
          validate: 'number',
        },
      ],
    },
  },
};

export const Q2_INSURANCE_DATA = {
  id: 7,
  question:
    'How does my insurance cost per mile compare to the national average?',
  inputs: {
    'insurance cost per mile': {
      title: 'Enter your current insurance cost per mile',
      type: 'input',
      validate: 'number',
    },
  },
};

export const INSURANCE_QUESTIONS = [Q1_INSURANCE_DATA, Q2_INSURANCE_DATA];
