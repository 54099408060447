export const Q1_FUEL_DATA = {
  id: 9,
  question: 'What was my average fuel mileage for the last month/year?',
  inputs: {
    'Enter total fuel gallons purchased and total miles travelled for each period.':
      {
        title:
          'Enter total fuel gallons purchased and total miles travelled for each period.',
        type: 'multiple-inputs',
        validate: 'number',
        inputs: [
          {
            name: 'month gallons purchased',
            label: 'Month gallons purchased',
            validate: 'number',
          },
          {
            name: 'month mileage',
            label: 'Month mileage',
            validate: 'number',
          },
          {
            name: 'year gallons purchased',
            label: 'Year gallons purchased',
            validate: 'number',
          },
          {
            name: 'year mileage',
            label: 'Year mileage',
            validate: 'number',
          },
        ],
      },
  },
};

export const Q2_FUEL_DATA = {
  id: 10,
  question: 'What was my average fuel cost per mile for the last month/year?',
  inputs: {
    'Enter total fuel cost and total miles travelled for each period.': {
      title: 'Enter total fuel cost and total miles travelled for each period.',
      type: 'multiple-inputs',
      validate: 'number',
      inputs: [
        {
          name: 'month fuel cost',
          label: 'Month fuel cost',
          validate: 'number',
        },
        {
          name: 'month mileage',
          label: 'Month mileage',
          validate: 'number',
        },
        {
          name: 'year fuel cost',
          label: 'Year fuel cost',
          validate: 'number',
        },
        {
          name: 'year mileage',
          label: 'Year mileage',
          validate: 'number',
        },
      ],
    },
  },
};

export const FUEL_QUESTIONS = [Q1_FUEL_DATA, Q2_FUEL_DATA];
