import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../../../shared/const/route-paths';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { AuthService } from '../../../services/auth/service';
import { UserApi } from '../../../services/user/api';
import { userActions } from '../../../stores/user/slice';
import { RegisterFormFields } from '../../../types/entities';
import { RegisterForm } from '..';
import S from './style.module.css';
import * as userSelectors from '../../../stores/user/selectors';

interface Props {
  formMode: 'create' | 'edit';
}

export const RegisterFormMode: React.FC<Props> = ({ formMode = 'create' }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userData = useAppSelector(userSelectors.getUserData);

  const onCreate = async (values: RegisterFormFields) => {
    await AuthService.register({
      name: values.name,
      username: values.username,
      password: values.password,
      agreement: values.agreement,
    });

    navigate(`/${Paths.REGISTRATION_SUCCESS}`);
  };

  const onEdit = async (values: RegisterFormFields) => {
    const response = await UserApi.editUserData({
      id: userData?.id,
      data: {
        name: values.name,
      },
    });

    dispatch(userActions.editUserData(response.data));
  };

  if (formMode === 'create') {
    return (
      <RegisterForm
        onSubmit={onCreate}
        layout="vertical"
        name="create"
        formMode={formMode}
        className={S.createForm}
      />
    );
  }

  return (
    <RegisterForm
      onSubmit={onEdit}
      name="edit"
      layout="vertical"
      formMode={formMode}
      className={S.editForm}
    />
  );
};
