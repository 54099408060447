import { Typography } from 'antd';
import { Container } from '../../components';

export const RegistrationSuccess = () => {
  return (
    <Container variant="card">
      <Typography.Title level={3}>
        {'Thanks for the registration!'}
      </Typography.Title>

      <Typography.Paragraph strong>
        {
          'To continue, please confirm your e-mail address. A confirmation request has been sent to your e-mail address.'
        }
      </Typography.Paragraph>
    </Container>
  );
};
