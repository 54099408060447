import { ITopicsMap } from './../../types/common';

export const topicInfo: ITopicsMap = {
  job: {
    title: 'Job Questions',
    subtitle: 'Select one of the job-related questions below:',
    questions: [
      {
        id: 1,
        text: 'How does my mileage rate as a company driver compare to the current average rate per mile?',
      },
      {
        id: 2,
        text: 'How does my mileage rate as an owner-operator compare to the current average rate per mile?',
      },
    ],
  },
  financial: {
    title: 'Financial Questions',
    subtitle: 'Select one of the Financial-related questions below:',
    questions: [
      {
        id: 3,
        text: 'How many miles have I done this week/month/year?',
      },
      {
        id: 4,
        text: 'What has been my gross income per mile this week/month/year?',
      },
      {
        id: 5,
        text: 'What has been my net income per mile this week/month/year?',
      },
    ],
  },
  insurance: {
    title: 'Insurance Questions',
    subtitle: 'Select one of the insurance-related questions below:',
    questions: [
      {
        id: 6,
        text: 'What is my current insurance cost per mile?',
      },
      {
        id: 7,
        text: 'How does my insurance cost per mile compare to the national average?',
      },
    ],
  },
  equipment: {
    title: 'Equipment Questions',
    subtitle: 'Select one of the equipment-related questions below:',
    questions: [
      {
        id: 8,
        text: 'What is the ownership cost per mile of my truck/trailer?',
      },
    ],
  },
  fuel: {
    title: 'Fuel Questions',
    subtitle: 'Select one of the fuel-related questions below:',
    questions: [
      {
        id: 9,
        text: 'What was my average fuel mileage for the last month/year?',
      },
      {
        id: 10,
        text: 'What was my average fuel cost per mile for the last month/year?',
      },
    ],
  },
  maintenance: {
    title: 'Maintenance Questions',
    subtitle: 'Select one of the maintenance-related questions below:',
    questions: [
      {
        id: 11,
        text: 'When is my truck next due for an oil change?',
      },
      {
        id: 12,
        text: 'When do I need to change my tires?',
      },
      {
        id: 13,
        text: 'What are my maintenance and repair costs per mile for the last year?',
      },
    ],
  },
};
