import { type AxiosResponse } from 'axios';
import { IChartData } from '../../types/common';
import { ENDPOINTS } from './../../shared/const/endpoints';
import makeRequest from '../../shared/utils/makeRequest';

export class AnalyticsService {
  static async getDatasets(): Promise<AxiosResponse<IChartData[]>> {
    return makeRequest({
      method: 'get',
      url: ENDPOINTS.GET_DATASETS,
    });
  }
}
