import { MAIN_COLOR, MAIN_COLOR_OPACITY_30 } from '../const/envVariables';

export const mainTheme = {
  token: {
    colorPrimary: MAIN_COLOR || '#D31E25',
    fontFamily: 'Open Sans',
  },
  components: {
    Button: {
      colorBorder: MAIN_COLOR || '#D31E25',
      colorText: MAIN_COLOR || '#D31E25',
      colorLink: MAIN_COLOR || '#D31E25',
      colorLinkHover: MAIN_COLOR || '#D31E25',
      borderRadiusLG: 32,
      colorLinkActive: MAIN_COLOR_OPACITY_30 || '#D31E25',
      defaultBg: 'transaprent',
      defaultColor: '#FFFFFF',
      defaultBorderColor: '#FFFFFF',
      defaultHoverBorderColor: '#FFFFFF',
      defaultHoverColor: MAIN_COLOR || '#D31E25',
      borderColorDisabled: '#BDBDBD',
      colorTextDisabled: '#BDBDBD',
    },
    Input: {
      paddingBlock: 6,
      borderRadius: 12,
      colorText: '#FFFFFF',
      colorTextDisabled: '#BDBDBD',
      colorTextPlaceholder: '#BDBDBD',
      colorBorder: '#FFFFFF80',
      colorIcon: '#BDBDBD',
      colorBgContainer: 'transparent',
    },
    Select: {
      controlHeight: 36,
      borderRadius: 12,
    },
    Layout: {
      colorBgBase: '#222222',
      bodyBg: '#222222',
      footerBg: '#222222',
      headerBg: '#222222',
      headerColor: '#FFFFFF',
      siderBg:
        'linear-gradient(310.13deg, #494949 -32.9%, #2F2F2F 16.69%, #333333 62.84%)',
    },
    Typography: {
      colorText: '#FFFFFF',
      colorTextHeading: '#FFFFFF',
      colorTextDescription: '#FFFFFF',
      fontWeightStrong: 700,
    },
    Spin: {
      colorBgBase: MAIN_COLOR || '#D31E25',
    },
    Menu: {
      colorBgContainer: '#F1F2F5',
      controlItemBgActive: '#FFFFFF',
    },
    Alert: {
      colorSuccessBg:
        'linear-gradient(245.06deg, #494949 0.86%, #2F2F2F 48.57%, #333333 92.97%)',
      colorSuccessBorder: '#222222',
      colorErrorBg:
        'linear-gradient(245.06deg, #494949 0.86%, #2F2F2F 48.57%, #333333 92.97%)',
      colorErrorBorder: '#222222',
    },
    Radio: {
      fontSizeLG: 20,
      borderRadiusLG: 24,
      lineWidth: 0,
    },
    Modal: {
      contentBg: '#222222',
    },
  },
};
