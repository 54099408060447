import { Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';

import { QUESTIONS_TOPICS } from '../../../../shared/const/questions';
import { Paths } from '../../../../shared/const/route-paths';
import { MainButton } from '../../../../components';
import S from './style.module.css';

const TOPICS_LINKS = [
  { key: QUESTIONS_TOPICS.JOB, link: Paths.APP.ASSISTANT.JOB },
  { key: QUESTIONS_TOPICS.FINANCIAL, link: Paths.APP.ASSISTANT.FINANCIAL },
  { key: QUESTIONS_TOPICS.INSURANCE, link: Paths.APP.ASSISTANT.INSURANCE },
  { key: QUESTIONS_TOPICS.EQUIPMENT, link: Paths.APP.ASSISTANT.EQUIPMENT },
  { key: QUESTIONS_TOPICS.FUEL, link: Paths.APP.ASSISTANT.FUEL },
  { key: QUESTIONS_TOPICS.MAINTENANCE, link: Paths.APP.ASSISTANT.MAINTENANCE },
];

export const Footer = () => {
  const { topic } = useParams();

  const filteredLinks = TOPICS_LINKS.filter(
    (item) => item.key.toLowerCase() !== topic,
  );

  return (
    <div className={S.footer_container}>
      Jump to{' '}
      {filteredLinks.map((link, index) => (
        <Fragment key={index}>
          <Link to={`../../../${link.link}`} key={link.key} relative="path">
            <MainButton className={S.linkBtn} htmlType="button" type="text">
              {link.key}
            </MainButton>
          </Link>
          {index < filteredLinks.length - 1 ? ' or ' : ''}
        </Fragment>
      ))}
    </div>
  );
};

export default Footer;
