export const Q1_JOB_DATA = {
  id: 1,
  question:
    'How does my mileage rate as a company driver compare to the current average rate per mile?',
  inputs: {
    'current mileage rate': {
      title: 'Enter current mileage rate as a company driver.',
      type: 'input',
      validate: 'number',
    },
  },
};

export const Q2_JOB_DATA = {
  id: 2,
  question:
    'How does my mileage rate as an owner-operator compare to the current average rate per mile?',
  inputs: {
    'current mileage rate': {
      title: 'Enter current mileage rate as an owner operator',
      type: 'input',
      validate: 'number',
    },
  },
};

export const JOB_QUESTIONS = [Q1_JOB_DATA, Q2_JOB_DATA];
