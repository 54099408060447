export const Q1_MAINTENANCE_DATA = {
  id: 11,
  question: 'When is my truck next due for an oil change?',
  inputs: {
    'Enter date and mileage of last oil change and desired oil change interval.':
      {
        title:
          'Enter date and mileage of last oil change and desired oil change interval.',
        type: 'multiple-inputs',
        validate: 'number',
        inputs: [
          {
            name: 'date',
            label: 'Date',
            validate: 'required',
          },
          {
            name: 'last oil change mileage',
            label: 'Last oil change mileage',
            validate: 'number',
          },
          {
            name: 'oil change interval',
            label: 'Oil change interval',
            validate: 'number',
          },
        ],
      },
  },
};

export const Q2_MAINTENANCE_DATA = {
  id: 12,
  question: 'When do I need to change my tires?',
  inputs: {
    'Enter date and mileage of last tire change by position (steer and drive) and desired change interval.':
      {
        title:
          'Enter date and mileage of last tire change by position (steer and drive) and desired change interval.',
        type: 'multiple-inputs',
        validate: 'required',
        inputs: [
          {
            name: 'date',
            label: 'Date',
            validate: 'required',
          },
          {
            name: 'last steer change mileage',
            label: 'Last steer change mileage',
            validate: 'number',
          },
          {
            name: 'steer change interval',
            label: 'Steer change interval',
            validate: 'number',
          },
          {
            name: 'last drive change mileage',
            label: 'Last drive change mileage',
            validate: 'number',
          },
          {
            name: 'drive change interval',
            label: 'Drive change interval',
            validate: 'number',
          },
        ],
      },
  },
};

export const Q3_MAINTENANCE_DATA = {
  id: 13,
  question:
    'What are my maintenance and repair costs per mile for the last year?',
  inputs: {
    'Enter total maintenance costs for past 12 months and total miles traveled last 12 months.':
      {
        title:
          'Enter total maintenance costs for past 12 months and total miles traveled last 12 months.',
        type: 'multiple-inputs',
        validate: 'number',
        inputs: [
          {
            name: 'total maintenance costs',
            label: 'Total maintenance costs',
            validate: 'number',
          },
          {
            name: 'total miles traveled',
            label: 'Total miles traveled',
            validate: 'number',
          },
        ],
      },
  },
};

export const MAINTENANCE_QUESTIONS = [
  Q1_MAINTENANCE_DATA,
  Q2_MAINTENANCE_DATA,
  Q3_MAINTENANCE_DATA,
];
