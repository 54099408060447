import { Paths } from './route-paths';

export const EXPIRES_IN = 5;
export const EXPIRES_IN_REFRESH = 1440;

export const EMPTY = '<blank>';

export const PROFILE_NAV_LINKS = [
  {
    label: 'Edit profile',
    path: Paths.APP.PROFILE.EDIT_PROFILE,
  },
  { label: 'Password', path: Paths.APP.PROFILE.PASSWORD },
];

export const FLEET_TYPE_OPTIONS = [
  { label: 'LTL', value: 'LTL' },
  { label: 'Truckload', value: 'TRUCKLOAD' },
  { label: 'Bulk/Tanker', value: 'BULK_TANKER' },
  { label: 'Intermodal', value: 'INTERMODAL' },
  { label: 'Specialty/Other', value: 'OTHER' },
];

export const DRIVERANSWERS_SENDER = 'DriverAnswers';
export const YOU_SENDER = 'You';
