import { JOB_QUESTIONS } from './job';
import { INSURANCE_QUESTIONS } from './insurance';
import { FUEL_QUESTIONS } from './fuel';
import { FINANCIAL_QUESTIONS } from './financial';
import { MAINTENANCE_QUESTIONS } from './maintenance';
import { EQUIPMENT_QUESTIONS } from './equipment';

export const ALL_QUESTIONS = [
  ...JOB_QUESTIONS,
  ...FINANCIAL_QUESTIONS,
  ...INSURANCE_QUESTIONS,
  ...EQUIPMENT_QUESTIONS,
  ...FUEL_QUESTIONS,
  ...MAINTENANCE_QUESTIONS,
];

export const ALL_QUESTIONS_WITH_KEYS = [
  { key: 'job', questions: JOB_QUESTIONS },
  { key: 'financial', questions: FINANCIAL_QUESTIONS },
  { key: 'equipment', questions: EQUIPMENT_QUESTIONS },
  { key: 'insurance', questions: INSURANCE_QUESTIONS },
  { key: 'fuel', questions: FUEL_QUESTIONS },
  { key: 'maintenance', questions: MAINTENANCE_QUESTIONS },
];

export const QUESTIONS_TOPICS = {
  JOB: 'job',
  FINANCIAL: 'financial',
  EQUIPMENT: 'equipment',
  INSURANCE: 'insurance',
  FUEL: 'fuel',
  MAINTENANCE: 'maintenance',
};
