import { NavLink } from 'react-router-dom';
import { MAIN_COLOR } from '../../shared/const/envVariables';
import { Container } from '../Container/Container';
import S from './style.module.css';

interface Props {
  navLinks: { label: string; path: string }[];
}

export const NavMenu: React.FC<Props> = ({ navLinks }) => {
  return (
    <Container
      style={{
        borderBottom: '1px solid #828282',
        justifyContent: 'flex-start',
        marginBottom: 20,
      }}
    >
      {navLinks.map((navLink) => {
        return (
          <NavLink
            key={navLink.path}
            to={navLink.path}
            className={S.navLink}
            style={({ isActive }) => ({
              position: 'relative',
              top: 1,
              padding: 4,
              borderBottom: isActive ? `2px solid ${MAIN_COLOR}` : 'none',
              color: isActive ? '#FFFFFF' : '#FFFFFF80',
              fontWeight: isActive ? 600 : 400,
            })}
          >
            {navLink.label}
          </NavLink>
        );
      })}
    </Container>
  );
};
