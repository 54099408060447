import { type Rule } from 'antd/es/form';
import type { NamePath } from 'antd/es/form/interface';

type ConfigKeys =
  | 'username'
  | 'password'
  | 'confirm_password'
  | 'first_name'
  | 'last_name'
  | 'phone'
  | 'email'
  | 'number'
  | 'agreement'
  | 'required';

type FormConfig<T> = { [key in ConfigKeys]: T };

export const CONFIG: FormConfig<Rule[]> = {
  username: [
    {
      type: 'email',
      message: 'The input is not valid Email!',
    },
    {
      required: true,
      message: 'Please input your Email!',
    },
  ],
  password: [
    {
      min: 10,
      message: 'Minimal length 10 characters!',
    },
    {
      max: 20,
      message: 'Maximum length 20 characters!',
    },
    {
      required: true,
      message: 'Please input your password!',
    },
    {
      pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$%^&*-]).*$/,
      message:
        'Must contain at least 1 uppercase, 1 lowercase letter and 1 special characters!',
    },
  ],
  confirm_password: [
    { required: true, message: 'Please confirm your password!' },

    // eslint-disable-next-line no-unused-vars
    ({ getFieldValue }: { getFieldValue: (name: NamePath) => string }) => ({
      validator(_: any, value: string) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve();
        }
        return Promise.reject(
          new Error('The new password that you entered do not match!'),
        );
      },
    }),
  ],
  first_name: [
    {
      required: true,
      message: 'Please input your First name!',
      whitespace: true,
    },
    {
      max: 60,
      message: 'Maximum length 60 characters!',
    },
  ],
  last_name: [
    {
      required: true,
      message: 'Please input your Last name!',
      whitespace: true,
    },
    {
      max: 60,
      message: 'Maximum length 60 characters!',
    },
  ],
  phone: [
    {
      required: true,
      message: 'Please input your phone number!',
    },
    { pattern: /\+[0-9]/, message: 'Incorrect phone format' },
    { min: 5, message: 'Too short phone number!' },
  ],
  email: [
    {
      type: 'email',
      message: 'The input is not valid Email!',
    },
  ],
  agreement: [
    {
      validator: (_: any, value: boolean) =>
        value
          ? Promise.resolve()
          : Promise.reject(new Error('Should accept agreement')),
    },
  ],
  number: [
    {
      required: true,
      message: 'This field is required',
    },
    { pattern: /^[0-9]+(\.[0-9]+)?$/, message: 'Please enter only numbers' },
  ],
  required: [
    {
      required: true,
      message: 'This field is required',
    },
  ],
};
