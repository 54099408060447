import { Typography } from 'antd';
import { useMemo } from 'react';
import { WechatWorkOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import { Paths } from '../../shared/const/route-paths';
import { MAIN_COLOR } from '../../shared/const/envVariables';
import S from './style.module.css';

interface Props {
  style?: React.CSSProperties;
  className?: string;
  setOpened?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SideBarMenu: React.FC<Props> = ({ className, setOpened }) => {
  const items = useMemo(
    () => [
      {
        label: 'Assistant',
        key: Paths.APP.ASSISTANT.INDEX,
        icon: <WechatWorkOutlined rev={undefined} />,
      },
    ],
    [],
  );

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column' }}
      className={className}
    >
      {items.map((item) => (
        <NavLink
          onClick={() => setOpened && setOpened(false)}
          id={S.navLink}
          key={item?.key}
          to={item.key}
          style={({ isActive }) =>
            isActive
              ? {
                  color: MAIN_COLOR,
                  background: '#fff',
                  borderLeft: `3px solid ${MAIN_COLOR}`,
                }
              : {}
          }
        >
          {item.icon}
          <Typography.Text className={S.label}>{item.label}</Typography.Text>
        </NavLink>
      ))}
    </div>
  );
};
