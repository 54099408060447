export const Q1_FINANCIAL_DATA = {
  id: 3,
  question: 'How many miles have I done this week/month/year?',
  inputs: {
    'current mileage': {
      title: 'Enter current odometer reading',
      type: 'input',
      validate: 'number',
    },
  },
};

export const Q2_FINANCIAL_DATA = {
  id: 4,
  question: 'What has been my gross income per mile this week/month/year?',
  inputs: {
    'Enter total income for last week/month/year and total mileage for each period':
      {
        title:
          'Enter total income for last week/month/year and total mileage for each period',
        type: 'multiple-inputs',
        validate: 'number',
        inputs: [
          {
            name: 'week income',
            label: 'Week income',
            validate: 'number',
          },
          {
            name: 'week mileage',
            label: 'Week mileage',
            validate: 'number',
          },
          {
            name: 'month income',
            label: 'Month income',
            validate: 'number',
          },
          {
            name: 'month mileage',
            label: 'Month mileage',
            validate: 'number',
          },
          {
            name: 'year income',
            label: 'Year income',
            validate: 'number',
          },
          {
            name: 'year mileage',
            validate: 'number',
            label: 'Year mileage',
          },
        ],
      },
  },
};

export const Q3_FINANCIAL_DATA = {
  id: 5,
  question: 'What has been my net income per mile this week/month/year?',
  inputs: {
    'Enter total income and expenses for last week/month/year and total mileage for each period.':
      {
        title:
          'Enter total income and expenses for last week/month/year and total mileage for each period.',
        type: 'multiple-inputs',
        validate: 'number',
        inputs: [
          {
            name: 'week income',
            label: 'Week income',
            validate: 'number',
          },
          {
            name: 'week expenses',
            label: 'Week expenses',
            validate: 'number',
          },
          {
            name: 'week mileage',
            label: 'Week mileage',
            validate: 'number',
          },
          {
            name: 'month income',
            label: 'Month income',
            validate: 'number',
          },
          {
            name: 'month expenses',
            label: 'Month expenses',
            validate: 'number',
          },
          {
            name: 'month mileage',
            label: 'Month mileage',
            validate: 'number',
          },
          {
            name: 'year income',
            label: 'Year income',
            validate: 'number',
          },
          {
            name: 'year expenses',
            label: 'Year expenses',
            validate: 'number',
          },
          {
            name: 'year mileage',
            validate: 'number',
            label: 'Year mileage',
          },
        ],
      },
  },
};

export const FINANCIAL_QUESTIONS = [
  Q1_FINANCIAL_DATA,
  Q2_FINANCIAL_DATA,
  Q3_FINANCIAL_DATA,
];
