import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import { IChartData } from './../../../types/common';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  responsive: true,
  aspectRatio: 4 / 1,
  plugins: {
    legend: {
      position: 'top' as const,
      align: 'end' as const,
      labels: {
        boxWidth: 21,
        boxHeight: 21,
        useBorderRadius: true,
        borderRadius: 4,
        padding: 24,
      },
    },
    tooltip: {
      boxPadding: 4,
    },
  },
};

export const LineChart = ({ data }: { data: IChartData }) => {
  return (
    <div style={{ minWidth: 1024 }}>
      <Line options={options} data={data} />
    </div>
  );
};

export default LineChart;
