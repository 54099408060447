import { Link } from 'react-router-dom';
import { Typography } from 'antd';

import Insurance from '../../assets/images/insurance.png';
import Job from '../../assets/images/job.png';
import Equipment from '../../assets/images/equipment.png';
import Fuel from '../../assets/images/fuel.png';
import Financial from '../../assets/images/financials.png';
import Maintenance from '../../assets/images/maintenance.png';
import { Paths } from '../../shared/const/route-paths';
import { MainButton } from '../../components';
import { Logo } from './../../components/Logo/Logo';
import { TextInfoBlock } from './../../components/TextInfoBlock';
import { QUESTIONS_TOPICS } from '../../shared/const/questions';
import logo from '../../assets/logo.svg';
import S from './styles/assistant.module.css';

export const Assistant = () => {
  const TOPICS = [
    {
      id: 1,
      title: QUESTIONS_TOPICS.JOB,
      image: Job,
      button: 'Job-Related',
      path: Paths.APP.ASSISTANT.JOB,
    },
    {
      id: 2,
      title: QUESTIONS_TOPICS.FINANCIAL,
      image: Financial,
      button: 'Financial',
      path: Paths.APP.ASSISTANT.FINANCIAL,
    },
    {
      id: 3,
      title: QUESTIONS_TOPICS.INSURANCE,
      image: Insurance,
      button: 'Insurance',
      path: Paths.APP.ASSISTANT.INSURANCE,
    },
    {
      id: 4,
      title: QUESTIONS_TOPICS.EQUIPMENT,
      image: Equipment,
      button: 'Equipment',
      path: Paths.APP.ASSISTANT.EQUIPMENT,
    },
    {
      id: 5,
      title: QUESTIONS_TOPICS.FUEL,
      image: Fuel,
      button: 'Fuel',
      path: Paths.APP.ASSISTANT.FUEL,
    },
    {
      id: 6,
      title: QUESTIONS_TOPICS.MAINTENANCE,
      image: Maintenance,
      button: 'Maintenance',
      path: Paths.APP.ASSISTANT.MAINTENANCE,
    },
  ];

  return (
    <>
      <TextInfoBlock>
        <Logo style={{ width: 'auto', height: 46 }} src={logo} />
        <Typography.Title level={1} style={{ marginBottom: 80 }}>
          Welcome to the DriverAnswers Assistant
        </Typography.Title>
      </TextInfoBlock>
      <div>
        <Typography.Title level={2} style={{ marginBottom: 40 }}>
          Select a topic:
        </Typography.Title>
        <div className={S.container}>
          {TOPICS.map((topic) => (
            <div key={topic.id} className={S.topicContainer}>
              <img src={topic.image} alt={topic.title} className={S.image} />

              <Link to={topic.path}>
                <MainButton
                  type="primary"
                  htmlType="button"
                  className={S.topicButton}
                >
                  {topic.button}
                </MainButton>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Assistant;
