import { Fragment } from 'react';
import { Form, Checkbox, Radio, Button, Space, Typography } from 'antd';

import useChat from './hooks/useChat';
import { ChatInfoBlock } from './components/info/ChatInfoBlock';
import { MainButton } from '../../components';
import { FormInput } from '../../components/FormInput/FormInput';
import { CONFIG } from '../../features/auth/RegisterForm/form-config';
import { DRIVERANSWERS_SENDER, YOU_SENDER } from '../../shared/const/common';
import S from './styles/chat.module.css';

export const AssistantChat = () => {
  const [form] = Form.useForm();

  const {
    onSubmitQuestion,
    messagesEndRef,
    messages,
    isLoading,
    activeQuestion,
    questionKey,
    onSubmitChat,
  } = useChat({
    form,
  });

  return (
    <>
      <ChatInfoBlock />

      <div className={S.container}>
        {messages.map(({ title, sender, ...question }, index) => (
          <Fragment key={index}>
            {sender === YOU_SENDER && (
              <div style={{ maxWidth: 780, marginLeft: 'auto' }}>
                <p className={S.sender_you}>{sender}</p>
                <div className={S.messages}>{title}</div>
              </div>
            )}

            {sender === DRIVERANSWERS_SENDER && (
              <Form
                form={form}
                requiredMark={false}
                onFinish={onSubmitQuestion}
              >
                <div style={{ maxWidth: 780 }}>
                  <p className={S.sender_driveranswers}>{sender}</p>
                  <div ref={messagesEndRef} className={S.messages}>
                    <p
                      style={{
                        marginBottom:
                          activeQuestion && activeQuestion.title === title
                            ? 40
                            : 0,
                      }}
                    >
                      {title}
                    </p>

                    {question.type === 'radio' &&
                      activeQuestion &&
                      activeQuestion.title === title && (
                        <Form.Item name={questionKey}>
                          <Radio.Group
                            onChange={onSubmitQuestion}
                            style={{ width: '100%' }}
                            size="large"
                          >
                            <Space
                              direction="vertical"
                              style={{ width: '100%' }}
                            >
                              {activeQuestion.options.map(
                                ({ value, label }) => (
                                  <Radio.Button
                                    key={label + Date.now()}
                                    value={value}
                                    className={S.radioBtn}
                                  >
                                    {label}
                                  </Radio.Button>
                                ),
                              )}
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                      )}

                    {question.type === 'input' &&
                      activeQuestion &&
                      activeQuestion.title === title && (
                        <div className={S.inputContainer}>
                          <FormInput
                            name={questionKey}
                            style={{ width: '100%', marginBottom: 0 }}
                            inputStyles={{
                              padding: '20px 24px',
                              marginBottom: 0,
                              fontSize: 20,
                              borderRadius: 24,
                            }}
                            rules={
                              question.validate === 'number'
                                ? CONFIG.number
                                : CONFIG.required
                            }
                          />
                          <Button
                            htmlType="submit"
                            type="text"
                            className={S.sendbtn}
                          >
                            Send
                          </Button>
                        </div>
                      )}

                    {question.type === 'checkbox' &&
                      activeQuestion &&
                      activeQuestion.title === title && (
                        <>
                          <Form.Item name={questionKey}>
                            <Checkbox.Group style={{ width: '100%' }}>
                              <Space
                                direction="vertical"
                                style={{ width: '100%' }}
                              >
                                {activeQuestion.options
                                  .filter(({ type }) => !type)
                                  .map(({ label, value }) => (
                                    <div
                                      key={value}
                                      className={S.checkBoxContainer}
                                    >
                                      <Checkbox value={value}>
                                        <div className={S.checkBoxLabel}>
                                          {label}
                                        </div>
                                      </Checkbox>
                                    </div>
                                  ))}
                              </Space>
                            </Checkbox.Group>
                          </Form.Item>
                          {activeQuestion.isAllowInput && (
                            <div className={S.inputContainer}>
                              <FormInput
                                placeholder="Other"
                                style={{ width: '100%', marginBottom: 0 }}
                                inputStyles={{
                                  padding: '20px 24px',
                                  fontSize: 20,
                                  borderRadius: 24,
                                }}
                                name={`${questionKey}_other`}
                                rules={[
                                  {
                                    validator(rule, value) {
                                      return new Promise((resolve, reject) => {
                                        const isArrayEmpty = !(
                                          form.getFieldValue(questionKey) || []
                                        ).length;

                                        if (isArrayEmpty && !value) {
                                          reject(
                                            'Select at least one option or fill in the "Other" field.',
                                          );
                                        }

                                        resolve();
                                      });
                                    },
                                  },
                                ]}
                              />
                            </div>
                          )}
                          <MainButton className={S.submitBtn} htmlType="submit">
                            Send
                          </MainButton>
                        </>
                      )}

                    {question.type === 'multiple-inputs' &&
                      activeQuestion &&
                      activeQuestion.title === title && (
                        <div>
                          {question.inputs.map((item, index) => (
                            <div key={index}>
                              <Typography.Title level={5}>
                                {item.label}
                              </Typography.Title>
                              <div className={S.inputContainer}>
                                <FormInput
                                  name={item.name}
                                  style={{ width: '100%' }}
                                  inputStyles={{
                                    padding: '20px 24px',
                                    fontSize: 20,
                                    borderRadius: 24,
                                  }}
                                  rules={
                                    item.validate === 'number'
                                      ? CONFIG.number
                                      : CONFIG.required
                                  }
                                />
                              </div>
                            </div>
                          ))}
                          <MainButton className={S.submitBtn} htmlType="submit">
                            Send
                          </MainButton>
                        </div>
                      )}
                  </div>
                </div>
              </Form>
            )}
          </Fragment>
        ))}

        {!questionKey && (
          <MainButton
            htmlType="submit"
            className={S.submitBtn}
            onClick={onSubmitChat}
            disabled={isLoading}
            loading={isLoading}
          >
            Submit
          </MainButton>
        )}
      </div>
    </>
  );
};

export default AssistantChat;
