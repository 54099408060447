import React, { useEffect, useRef } from 'react';
import { Typography } from 'antd';

import Logo from '../../assets/logo.svg';
import { Footer } from './components/footer';
import { Questions } from './components/questions';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { MainButton } from '../../components';
import S from './styles/success.module.css';

export const AssistantSuccess = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { topic } = useParams();
  const answerRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (answerRef.current) {
      answerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    if (!state) {
      navigate(`/${topic}`);
    }
  }, [state, navigate, topic]);

  return (
    <div className={S.container}>
      <div className="flex-[1_0_auto]">
        <div className={S.titleContainer}>
          <img src={Logo} alt="Logo" />

          <Typography.Title level={1} style={{ margin: 0 }}>
            Assistant
          </Typography.Title>
        </div>
      </div>

      <div ref={answerRef} style={{ marginBottom: 40 }}>
        <div style={{ maxWidth: 780 }}>
          <p className={S.answerTitle}>Your Answer</p>
          <div className={S.answer}>
            <p>
              {state &&
                state.results &&
                state.results.split('\n').map((line: string, index: number) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
            </p>
          </div>
        </div>
      </div>

      <div className={S.info}>
        <div>
          Did we answer the question satisfactorily? What other information are
          you interested in on this topic? Please let us know{' '}
          <MainButton
            htmlType="button"
            type="link"
            href="https://driveranswers.co"
            target="_blank"
            rel="noreferrer"
            className={S.linkBtn}
          >
            here
          </MainButton>
          .
        </div>

        <Questions />

        <Footer />
      </div>
    </div>
  );
};

export default AssistantSuccess;
