import { useEffect, useState } from 'react';
import { Typography } from 'antd';
import { AxiosError } from 'axios';

import { IChartData } from './../../types/common';
import { AnalyticsService } from '../../services/analytics/api';
import { Loading } from '../../components/Loading/Loading';
import { LineChart } from './components/LineChart';
import { useAppDispatch } from '../../hooks/hooks';
import { actions as alertActions } from '../../stores/alert/slice';
import S from './analytics.module.css';

const DataSources = [
  {
    label: 'My Index',
    borderColor: 'rgba(211, 30, 37, 1)',
    backgroundColor: 'rgba(211, 30, 37, 1)',
  },
  {
    label: 'National Index',
    borderColor: 'rgba(20, 20, 23, 1)',
    backgroundColor: 'rgba(20, 20, 23, 1)',
  },
];

export const Analytics = () => {
  const dispatch = useAppDispatch();
  const [fetchData, setFetchData] = useState<IChartData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const { data } = await AnalyticsService.getDatasets();

        data && setFetchData(data);
      } catch (error) {
        dispatch(
          alertActions.addAlert({
            message: 'Data fetching has been failed',
            type: 'error',
            status: error instanceof AxiosError ? error.response?.status : '',
          }),
        );
      } finally {
        setLoading(false);
      }
    };

    getData();
    // eslint-disable-next-line
  }, []);

  const chartsData = fetchData.map(({ topic, datasets }) => {
    const chartDatasets = datasets.map(({ data }, index) => ({
      ...DataSources[index],
      data,
    }));

    return { topic, datasets: chartDatasets };
  });

  if (loading) {
    return <Loading size="large" />;
  }

  return (
    <div className={S.container}>
      {chartsData?.map((data, index) => (
        <div className={S.chartContainer} key={index + data.topic}>
          <Typography.Title className={S.title} level={2}>
            {data.topic}
          </Typography.Title>
          <div style={{ width: '100%', overflow: 'auto' }}>
            <LineChart data={data} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Analytics;
