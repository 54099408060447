import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Paths } from '../../shared/const/route-paths';

export const AssistantMenu = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const AssistantURL = `${Paths.APP.INDEX}/${Paths.APP.ASSISTANT.INDEX}`;
  const backUrl = pathname === AssistantURL ? Paths.APP.INDEX : AssistantURL;

  return (
    <>
      <Button
        onClick={() => navigate(backUrl)}
        type="text"
        size="large"
        style={{ color: '#FFFFFF', fontWeight: 600 }}
      >
        <ArrowLeftOutlined rev={undefined} />
        Back
      </Button>

      <Outlet />
    </>
  );
};

export default AssistantMenu;
