export const Q1_EQUIPMENT_DATA = {
  id: 8,
  question: 'What is the ownership cost per mile of my truck/trailer?',
  inputs: {
    'Enter monthly truck and trailer payments for past year and mileage for last year.':
      {
        title:
          'Enter monthly truck and trailer payments for past year and mileage for last year.',
        type: 'multiple-inputs',
        validate: 'number',
        inputs: [
          {
            name: 'monthly payment',
            label: 'Monthly payment',
            validate: 'number',
          },
          {
            name: 'last year mileage',
            label: 'Last year mileage',
            validate: 'number',
          },
        ],
      },
  },
};

export const EQUIPMENT_QUESTIONS = [Q1_EQUIPMENT_DATA];
